import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "820px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.29268292682927%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABgcA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwIE/9oADAMBAAIQAxAAAAFUftQgFi+tOnT/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAMEAQIGBTP/2gAIAQEAAQUC7mQCR93PwjUoDr3e9dRaYG/WJY//xAAaEQACAgMAAAAAAAAAAAAAAAAAAgMEFTJS/9oACAEDAQE/AVndNTIWOj//xAAYEQADAQEAAAAAAAAAAAAAAAAAAQIUMf/aAAgBAgEBPwFwq6ZoP//EACEQAAEEAQMFAAAAAAAAAAAAAAEAAgMREgQUQSEiIzKx/9oACAEBAAY/AnTx9jpDZZQpRufAyWNxw8ftZWDtHiRximrTAE1uI/qPThf/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFhkf/aAAgBAQABPyEF3UyPdVK/qwa4sMdt2skX0g4SxDKEhUZryf/aAAwDAQACAAMAAAAQO+//xAAaEQACAgMAAAAAAAAAAAAAAAAAAREhkaHR/9oACAEDAQE/EE9owJNLRcP/xAAYEQACAwAAAAAAAAAAAAAAAAAAARFhsf/aAAgBAgEBPxB9Ap0//8QAGxABAQADAQEBAAAAAAAAAAAAAREAITFBUWH/2gAIAQEAAT8Q0eeKcbAQV3zre4fwD4fpQWjOmm+YStBoil6Ykaxh/dYMRJYDp5jQIVBVx//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "pêche_a_la_Mouche_NY",
        "title": "pêche_a_la_Mouche_NY",
        "src": "/static/86ae9f20d8aebe8658a165741f823e17/63a81/IMG_2404Res1000x482.jpg",
        "srcSet": ["/static/86ae9f20d8aebe8658a165741f823e17/bd2b6/IMG_2404Res1000x482.jpg 205w", "/static/86ae9f20d8aebe8658a165741f823e17/ceeba/IMG_2404Res1000x482.jpg 410w", "/static/86ae9f20d8aebe8658a165741f823e17/63a81/IMG_2404Res1000x482.jpg 820w", "/static/86ae9f20d8aebe8658a165741f823e17/dbdff/IMG_2404Res1000x482.jpg 1000w"],
        "sizes": "(max-width: 820px) 100vw, 820px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <hr></hr>
    <h2 {...{
      "id": "je-me-présente"
    }}>{`Je me présente:`}</h2>
    <p>{`Mon nom est François Meehan, je suis:`}</p>
    <ul>
      <li parentName="ul">{`Informaticien`}</li>
      <li parentName="ul">{`Photographe`}</li>
      <li parentName="ul">{`Videographe`}</li>
      <li parentName="ul">{`Épicurien`}</li>
      <li parentName="ul">{`Pêcheur`}</li>
    </ul>
    <p>{`Comment allez-vous?`}</p>
    <p>{`Si vous êtes en train de lire cette page, cela veut dire que nous avons déjà une chose en commun: la curiosité!`}</p>
    <p>{`L'informatique m'a offert toute sorte d'opportunités dans des domaines très variés. J'ai fait beaucoup de gestion de projet et j'adore écrire de la documentation, le support à l'usager et l'intégration d'applications en entreprise.`}</p>
    <p>{`J'ai accompli des projets dont je suis particulièrement fier, entre autres, d'avoir été publié des dans des revues spécialisées, montées une solution de surveillance réseaux dans le cadre d'une émission de télé-réalité, publié le code source de 2 intégrations que j'ai mises au point, celle de trappes SNMP`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` dans Nagios et celle de Nagios-RT (Request Tracker), qui a été utilisé par American Airlines.`}</p>
    <p>{`Mais j'ai aussi un côté artistique, la créativité, je suis tombé dedans.`}</p>
    <p>{`Et j'utilise celle-ci pour la création de matériel audio visuel, la photographie, la production de vidéo et la composition de trame musicale`}</p>
    <h3 {...{
      "id": "projets-courants"
    }}>{`Projets courants`}</h3>
    <p>{`Voici quelques projets sur lesquelles je travaille présentement.`}</p>
    <h4 {...{
      "id": "conception-de-matériel-audio-visuel-sur-ios"
    }}>{`Conception de matériel audio-visuel sur IOS`}</h4>
    <p>{`Je suis un utilisateur des produits Apple, tout particulièrement les appareils iPhone et iPad que j’utilise dans la création et montage de vidéos, la photographie, la composition de trame musicale, composition de billet de blogue et j’en passe.`}</p>
    <p>{`Ayant fait l’acquisition de plus de 1,100 applications sous IOS, j’ai monté une combinaison de logiciels ultra-performants, beaucoup plus abordable que leurs équivalences roulant sous Windows et Mac OSX.`}</p>
    <h4 {...{
      "id": "gatsby-js----graphql----mdx"
    }}>{`Gatsby JS -- GraphQL -- MDX`}</h4>
    <ul>
      <li parentName="ul">{`Adapter Gatsby`}<sup parentName="li" {...{
          "id": "fnref-2"
        }}><a parentName="sup" {...{
            "href": "#fn-2",
            "className": "footnote-ref"
          }}>{`2`}</a></sup>{` JS, un générateur de sites Web statique, pour diverses `}<em parentName="li">{`saveurs`}</em>{` de site WEB`}</li>
      <li parentName="ul">{`Approfondir les fonctionnalités taxonomiques de Gatsby JS pour fin de gestion documentaire`}</li>
      <li parentName="ul">{`Convertir ma documentation technique à MDX`}</li>
    </ul>
    <br />
    <br />
    <h4 {...{
      "id": "gestion-de-projet-kanban"
    }}>{`Gestion de projet Kanban`}</h4>
    <ul>
      <li parentName="ul">{`Promotion, solutions de gestion de projets agile en milieu manufacturier en utilisant Wekan`}<sup parentName="li" {...{
          "id": "fnref-3"
        }}><a parentName="sup" {...{
            "href": "#fn-3",
            "className": "footnote-ref"
          }}>{`3`}</a></sup>{` Vivify ou Trello`}</li>
    </ul>
    <br />
    <br />
    <h3 {...{
      "id": "iot-et-automatisation-de-processus"
    }}>{`IOT et automatisation de processus`}</h3>
    <ul>
      <li parentName="ul">{`Tests du protocole MQTT avec Node Red et/ou Huginn en tant que `}<em parentName="li">{`hub`}</em>{` de communications, pour fin de géolocalisation dans un contexte IOT`}</li>
      <li parentName="ul">{`Intégrer les Webhooks de Wekan pour les présenter en format RSS`}</li>
    </ul>
    <br />
    <br />
    <h3 {...{
      "id": "statut"
    }}>{`Statut`}</h3>
    <p><strong parentName="p">{`je suis à la recherche d'opportunités informatique`}</strong></p>
    <p>{`Vous avez besoin d'un informaticien polyvalent? `}</p>
    <p>{`Voici mon C.V.:   `}<a href="https://drive.google.com/file/d/1yQkDRLupiOFY-ZYBdmoz6c09c-4D2mPF/view?usp=sharing" target="_blank">{`François Meehan C.V.`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "photographie-artistique"
    }}>{`Photographie artistique`}</h2>
    <p>{`La photo occupe une très grande place dans ma vie et elle m'a fait vivre toutes sortes d'expériences du fait que j'expérimente constamment de nouvelles techniques, de nouvelles approches. `}</p>
    <p>{`Je suis fier d'avoir eu plus de 1,2 million de visites sur mon site Flickr`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{`, vu mes oeuvres exposer au Musée d’art contemporain de Montréal, monté des tablettes digitales pour des expositions muséales qui sont maintenant dotées de fonctions, par exemple `}<em parentName="p">{`le mode portrait`}</em>{` qui n'a pas d'équivalence chez les caméras digitales conventionnelles.`}</p>
    <p>{`Depuis 3 ans environ, j'utilise strictement que mon iPhone et mon iPad pour faire de la photos. `}</p>
    <p><strong parentName="p">{`Vous êtes à la recherche d'un photographe voyant les choses d'un autre oeil, possédant un regard vraiment différent?`}</strong></p>
    <p>{`Voici un aperçu de ma photographie:  `}<a href="https://www.flickr.com/photos/12149783@N04/" target="_blank">{`Mes photos sur Flickr`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "la-pêche-la-cuisine-et-autre-méfaits"
    }}>{`La pêche, la cuisine et autre méfaits`}</h2>
    <p>{`Selon mon épouse, je suis un adepte de la cuisine `}<em parentName="p">{`chaotique`}</em>{`, pouvant transformer une cuisine en ordre, en incroyable cafouillis le temps de le dire. `}</p>
    <p>{`Avec le temps, j'ai mis au point et documenté toute sorte de  recettes: de la frite au four au caramel salé, en passant par les sauces, les vinaigrettes...`}</p>
    <p>{`Des recettes simples, mais avec des petits trucs de mon cru que je vais bientôt partager avec vous.  `}</p>
    <p>{`Également au menu, des histoires de pêche, des Espritdebottines et autres anectotes.`}</p>
    <p>{`Je vous remercie de votre visite,`}</p>
    <br />
    <p><em parentName="p">{`François`}</em></p>
    <AuthorBanner mdxType="AuthorBanner"></AuthorBanner>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Article dans la revue `}<a parentName="li" {...{
            "href": "https://www.drdobbs.com/snmp-trap-handling-with-nagios/199102017"
          }}>{`SysAdmin`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://www.gatsbyjs.org/"
          }}>{`Gatsby`}</a>{` générateur de site WEB statique `}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}><a parentName="li" {...{
            "href": "https://www.gatsbyjs.org/"
          }}>{`Wekan`}</a>{` Wekan gestion de projet Kanban`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}><a parentName="li" {...{
            "href": "https://www.flickr.com/photos/12149783@N04/stats"
          }}>{`Stats`}</a>{` de visites sur Flickr`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      